@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family: 'Roboto', sans-serif;
}
.Active{
    @apply text-mainPink md:border-[2px] md:border-mainPink  dark:border-mainBlue dark:text-mainBlue
}
.Header__Link{
    @apply px-[15px] text-[16px] py-[6px] rounded-[36px] text-white font-medium transition-all duration-500 ease-linear cursor-pointer hover:text-mainPink
}
.Header__Link_Small{
  @apply text-[#6f6078] text-[26px] uppercase font-black w-full bg-[#23182C] px-4 py-2 border-b-[1px] border-[#352540] cursor-pointer transition-all duration-500 ease-linear hover:bg-[#eeeeee] 
}
#Header{
    z-index:9999999999999 !important;
}

@keyframes image{
    from {
        transform:scale(1.2)
    }
    to{
        transform:scale(1)

    }
}


.background-slider {
    position: absolute; 
    top:0;
    left:0;
    z-index: -1;
    height:100%;
    width:100%;
    background-size: cover;
    background-position: center;
}
.TabList li{
    @apply bg-mainPink dark:bg-mainBlue
}
.TabList .react-tabs__tab--selected{
    @apply bg-[#23182C] border-none  dark:bg-[#111227] transition-all duration-300
}
.TabList .react-tabs__tab--selected .Header_text {
    @apply  text-mainPink dark:text-mainBlue transition-all duration-300  font-Fira font-black
}
.Header_text{
    @apply transition-all duration-300 text-[16px] md:text-[22px] lg:text-[29px] font-Fira font-black 
}
.Header_small{
    @apply text-white
}
.Header_small{
    @apply max-md:text-[13px]
}
.For_after_before1{
    @apply relative after:absolute after:content-['01']  after:right-6 sm:after:right-0  after:h-fit after:w-fit md:after:leading-[0px] after:flex after:items-end after:p-0  after:font-Fira after:font-black after:opacity-50 after:text-[8vw] 
}
.For_after_before2{
    @apply relative after:absolute after:content-['02']  after:right-6 sm:after:right-0  after:h-fit after:w-fit md:after:leading-[0px] after:flex after:items-end after:p-0  after:font-Fira after:font-black after:opacity-50 after:text-[8vw] 
}
.For_after_before3{
    @apply relative after:absolute after:content-['03']  after:right-6 sm:after:right-0  after:h-fit after:w-fit md:after:leading-[0px] after:flex after:items-end after:p-0  after:font-Fira after:font-black after:opacity-50 after:text-[8vw] 
}
.Shadow{
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.Shadow-Card:hover{
    box-shadow: 0 1px 10px rgba(128, 128, 128, 0.05);
}
.Shadow-Blog:hover{
    box-shadow: 0 0px 30px rgba(20, 20, 20, 0.1);
}
.Shadow-Inset:hover{
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}